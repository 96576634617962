import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { CopyMemoData } from './copy-memo.model';
import { SaveCopyMemo } from './copy-memo.actions';
import { MemoDetail } from 'src/app/modules/memos/model/memo.model';

@State<CopyMemoData<any>>({
  name: 'copyMemoData',
  defaults: {
    copyMemoData: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class CopyMemoState {
  constructor() {}

  @Selector()
  static copyMemo(
    state: CopyMemoData<MemoDetail>,
  ): CopyMemoData<MemoDetail> {
    return state;
  }

  @Action(SaveCopyMemo)
  saveCopyMemo(
    { setState }: StateContext<CopyMemoData<MemoDetail>>,
    { payload },
  ): void {
    setState({
      ...payload,
    });
  }
}
